.movie-card {
  background-color: #333333; /* Dark background for the card */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.2s;
  color: #e0e0e0; /* Light text color */
  cursor: pointer;
  max-width: 224px;
}

.movie-card img {
  width: 100%;
  height: auto;
  display: block;
}

.movie-card-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.movie-card h3 {
  font-size: 1em;
  margin: 10px 0 5px;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.movie-card p {
  font-size: 0.9em;
  color: #bbbbbb; /* Light text color for the description */
  margin: 5px 0;
}

.added-date {
  font-size: 0.8em;
  color: #bbbbbb;
  margin-top: 5px;
}
