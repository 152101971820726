/* WatchListPage.css */
.watchlist-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #121212; /* Dark background for dark mode */
}

/* specifically for ultra-wide screens, such as 4K monitors.  */
@media (min-width: 1920px) {
  .watchlist-page {
    max-width: 1600px;
  }
}

.watchlist-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Align items at the bottom */
  margin-bottom: 20px;
}

.watchlist-header h1 {
  font-size: 2.5em;
  margin: 0;
  color: #ffffff; /* Light text color */
}

.sort-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 5px;
}

.sort-buttons {
  display: flex;
  gap: 10px;
}

.link-to-home {
  margin-bottom: 5px;
}

.watchlist-movies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
}

.movie-card {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.movie-card:hover {
  transform: scale(1.05);
}

.movie-card img {
  width: 100%;
  border-radius: 10px;
}

.movie-card-watched {
  border: 3px solid green;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.6);
}

.watched-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: green;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: bold;
}

.movie-poster {
  border-radius: 10px;
  transition: opacity 0.3s ease;
}

.movie-card-watched .movie-poster {
  opacity: 0.7;
}
