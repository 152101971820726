/* Popup.css */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto; /* Ensure the content can scroll if it's too large */
}

.popup-content {
  background: #2c2c2c;
  padding-bottom: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  max-height: 90%; /* Ensure the content does not exceed the viewport height */
  overflow: auto; /* Scroll if content exceeds the viewport height */
}

.popup-content img {
  max-width: 100%; /* Ensure the image fits within the content area */
  height: auto;
  border-radius: 15px;
  margin-bottom: 20px;
}

.popup-content button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Media queries to adjust the image size based on the screen size */
@media (max-height: 800px) {
  .popup-content img {
    max-height: 50vh; /* Set a maximum height of 50% of the viewport height */
  }
}

@media (max-width: 500px) {
  .popup-content img {
    max-height: 50vw; /* Set a maximum height of 50% of the viewport width */
  }
}

