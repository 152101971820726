/* global.css */
body {
  background-color: #121212;
  color: #e0e0e0;
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ffffff;
}

a {
  color: #1e90ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button,
.button-style {
  background-color: #1a6e4c;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover,
.button-style:hover {
  background-color: #249c6c;
  text-decoration: none;
}
