/* HomePage.css */
.homepage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #121212; /* Dark background for dark mode */
}

/* specifically for ultra-wide screens, such as 4K monitors.  */
@media (min-width: 1920px) {
  .homepage {
    max-width: 1600px;
  }
}

.search-form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-form input {
  width: 60%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  background-color: #333333;
  color: #e0e0e0;
}

.search-form input:focus {
  outline: 2px solid green; /* Change the focus outline color to green */
}

.movie-results {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
}

.auth-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.auth-buttons .username-button {
  cursor: default; /* No pointer cursor */
  display: flex;
  align-items: center;
  gap: 10px;
}

.watch-list-link {
  display: inline-block;
  margin-top: 20px;
}
